import React, { Component, useEffect } from "react";
import "./Transcribe.css";
import "../../styles/type-scale-system.css";
import {
  transcribePageDetails,
  progessLoaderText,
  improveCardInfo,
  ratingModalTranscribeInfo,
} from "../../cms/generalCMS";
import { ACTIONTYPE, ACTION_AREA, HOME } from "../../utils/constants";
import Uploader from "../../components/uploader/Uploader";
import DividerLineIcon from "../../icons/divider-line.svg";
import Recorder from "../../components/recorder/Recorder";
import { transcribeAudioSync } from "../../helpers/api/transcribe";
import { handleErrorMessage } from "../../helpers/api/apiHelper";
import { getCookie } from "../../helpers/cookie";
import ProgressBar from "../../components/progressBar/ProgressBar";
import ResultCard from "../../components/resultCard/ResultCard";
import SmileIcon from "../../icons/smile-icon.svg";
import RepeatIcon from "../../icons/repeat-icon.svg";
import FullStarIcon from "../../icons/full-star-icon.svg";
import ErrorCard from "../../components/errorCard/ErrorCard";
import RatingModal from "../../components/rating/RatingModal";
import PropTypes from "prop-types";

class Transcribe extends Component {
  constructor(props) {
    super(props);

    let { errorDetails, isMobile } = this.props.globalState;

    this.state = {
      recording: false,
      file: null,
      uploading: false,
      uploadComplete: false,
      errorMessage: errorDetails.errorMessage,
      transcribing: false,
      processProgress: 0,
      transcribeComplete: false,
      transcriptionResults: "",
      complete: false,
      isRating: false,
      uploadDone: false,
      failureArea: errorDetails.failureArea,
      languageId: "",
      isMobile: isMobile,
    };

    this.handleFileStateUpdate = this.handleFileStateUpdate.bind(this);
    this.handleTranscribing = this.handleTranscribing.bind(this);
    this.handleRecordingState = this.handleRecordingState.bind(this);
    this.handleClearStates = this.handleClearStates.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.setIsRating = this.setIsRating.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (!this.state.uploading) {
      window.scrollTo(0, 0);
    }

    if (prevProps.globalState !== this.props.globalState) {
      const { errorDetails, isMobile } = this.props.globalState;
      this.setState({
        errorMessage: errorDetails.errorMessage,
        failureArea: errorDetails.failureArea,
        isMobile: isMobile,
      });
    }
  }

  handleFileStateUpdate(file) {
    this.setState({ file: file, uploading: true });

    this.handleUploadProgressBar();
  }

  handleUploadProgressBar = () => {
    const simulateUpload = () => {
      const totalSteps = 100;
      let currentStep = 0;

      const uploadInterval = setInterval(() => {
        currentStep++;
        this.setState({ processProgress: (currentStep / totalSteps) * 100 });

        if (currentStep === totalSteps) {
          clearInterval(uploadInterval);
          currentStep = 0;
        }
      }, 50);
    };

    simulateUpload();
  };

  handleUploadComplete() {
    this.setState({
      uploadComplete: true,
      uploading: false,
      processProgress: 0,
    });
  }

  handleTranscribeComplete = (transcriptionResults, languageId) => {
    this.setState({
      transcribeComplete: true,
      transcribing: false,
      transcriptionResults: transcriptionResults,
      languageId: languageId,
    });
  };

  async handleTranscribing() {
    const { file, uploadDone } = this.state;

    let tokenObject = getCookie("requestClientToken");
    let requestClientToken = JSON.parse(tokenObject);

    const totalSteps = uploadDone ? 80 : 50;
    let currentStep = 0;

    const simulateProgress = () => {
      const progressInterval = setInterval(() => {
        currentStep += 5;
        if (currentStep >= totalSteps || this.state.processProgress === 100) {
          clearInterval(progressInterval);
        } else {
          this.setState({ processProgress: currentStep });
        }
      }, 200);
    };

    simulateProgress();

    try {
      const transcribeResponse = await transcribeAudioSync(
        file,
        requestClientToken.token,
        this.props.dispatch
      );

      if (transcribeResponse) {
        this.handleTranscribeComplete(
          transcribeResponse.transcription_text,
          transcribeResponse.language_code
        );
      }
    } catch (err) {
      handleErrorMessage(
        err.message,
        500,
        this.props.dispatch,
        ACTION_AREA.TRANSCRIBE
      );
    }
  }

  handleRecordingState(action, state, data) {
    switch (action) {
      case ACTIONTYPE.RECORDING:
        return this.setState({ recording: state });
      case ACTIONTYPE.STOP_RECORDING:
        return this.setState({ recording: state, file: data });
      case ACTIONTYPE.TRANSCRIBE:
        return this.setState({ transcribing: state });
      default: // transcribing and result viewing is done
        return this.setState({ complete: state });
    }
  }

  handleClearStates() {
    this.setState({
      recording: false,
      file: null,
      uploading: false,
      uploadComplete: false,
      errorMessage: "",
      transcribing: false,
      processProgress: 0,
      transcribeComplete: false,
      transcriptionResults: "",
      complete: false,
      isRating: false,
      uploadDone: false,
      failureArea: "",
      languageId: "",
    });

    handleErrorMessage("", 0, this.props.dispatch, ACTION_AREA.TRANSCRIBE);
  }

  setIsRating() {
    this.setState({ isRating: true });
    this.props.dispatch({
      type: "UPDATE_SHOW_RATE_SERVICE_MODAL",
      payload: { show: true },
    });
  }

  render() {
    let {
      recording,
      uploading,
      uploadComplete,
      file,
      processProgress,
      transcribing,
      transcribeComplete,
      complete,
      isRating,
      transcriptionResults,
      errorMessage,
      failureArea,
      languageId,
      isMobile,
    } = this.state;

    return (
      <div className="transcribe-container page-background">
        {/* TITLE */}
        <div className="transcribe-title-container">
          <div className={isMobile ? "vulavula-heading-3" : "title-container"}>
            {transcribePageDetails.title}
          </div>
          <div
            className="vulavula-paragraph"
            style={{ maxWidth: "786px", textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: transcribePageDetails.body }}
          />
        </div>

        {/* CONTROLS */}
        <div
          className="control-option-container"
          style={{
            gap:
              (!recording && !uploading && file === null) ||
              (uploadComplete && (file !== null || file !== undefined)) ||
              (!recording && !uploadComplete && file === null)
                ? isMobile
                  ? "24px"
                  : "40px"
                : "0",
          }}
        >
          {errorMessage !== "" && failureArea === ACTION_AREA.TRANSCRIBE ? (
            <ErrorCard
              errorMessage={errorMessage}
              handleClearStates={this.handleClearStates} // TODO: create function that handles for when in different states
            />
          ) : !transcribing && !transcribeComplete ? (
            <div
              className="control-container"
              style={{
                gap:
                  (!recording && !uploading && file === null) ||
                  (uploadComplete && (file !== null || file !== undefined)) ||
                  (!recording &&
                    !uploadComplete &&
                    (file !== null || file !== undefined))
                    ? isMobile
                      ? "24px"
                      : "40px"
                    : "0",
              }}
            >
              {/* RECORD COMPONENT */}
              <div
                className="inner-control-container"
                style={{
                  opacity:
                    recording ||
                    (!recording && !uploading) ||
                    (uploadComplete && (file !== null || file !== undefined))
                      ? 1
                      : 0,
                  transition: "opacity .5s ease-in-out",
                  height:
                    recording ||
                    (!recording && !uploading) ||
                    (uploadComplete && (file !== null || file !== undefined))
                      ? "auto"
                      : 0,
                  display:
                    recording ||
                    (!recording && !uploading) ||
                    (uploadComplete && (file !== null || file !== undefined))
                      ? "flex"
                      : "none",
                }}
              >
                {recording ||
                (!recording && !uploading) ||
                (uploadComplete && (file !== null || file !== undefined)) ? (
                  <Recorder
                    recording={recording}
                    handleRecordingState={this.handleRecordingState}
                    handleClearStates={this.handleClearStates}
                    handleTranscribing={this.handleTranscribing}
                    audioBlob={file}
                  />
                ) : (
                  ""
                )}
              </div>

              {/* DIVIDER */}
              <div className="divider-container">
                <div
                  className="divider"
                  style={{
                    opacity:
                      !recording && !uploadComplete && file === null ? 1 : 0,
                    transition: "opacity .5s ease-in-out",
                    height:
                      !recording && !uploadComplete && file === null
                        ? "auto"
                        : 0,
                  }}
                >
                  <img
                    className="divider-line"
                    src={DividerLineIcon}
                    alt="divider line 1"
                  />
                  <div className="vulavula-heading-3">
                    {transcribePageDetails.deviderText}
                  </div>
                  <img
                    className="divider-line"
                    src={DividerLineIcon}
                    alt="divider line 2"
                  />
                </div>
              </div>

              {/* UPLOADER COMPONENT */}
              <div
                className="inner-control-container"
                style={{
                  opacity:
                    (!uploading && !recording && file === null) ||
                    (uploading && !recording)
                      ? 1
                      : 0,
                  transition: "opacity 0.5s ease-in-out",
                  height:
                    (!uploading && !recording && file === null) ||
                    (uploading && !recording)
                      ? "auto"
                      : 0,
                  display:
                    (!uploading && !recording && file === null) ||
                    (uploading && !recording)
                      ? "flex"
                      : "none",
                }}
              >
                <Uploader
                  uploading={uploading}
                  complete={uploadComplete}
                  processProgress={processProgress}
                  handleFileStateUpdate={this.handleFileStateUpdate}
                  handleUploadProgressBar={this.handleUploadProgressBar}
                  handleUploadComplete={this.handleUploadComplete}
                  errorMsg={
                    errorMessage !== "" &&
                    failureArea === ACTION_AREA.TRANSCRIBE
                      ? errorMessage
                      : ""
                  }
                  dispatch={this.props.dispatch}
                />
              </div>
            </div>
          ) : transcribing && !transcribeComplete ? (
            <div className="process-loader-container">
              {/* PROCESSING LOADER */}
              <div
                className="vulavula-paragraph-bold flashing-text"
                style={{ color: "#E29900", paddingTop: "30px" }}
              >
                {progessLoaderText}
              </div>
              <div className="progress-bar-container">
                <ProgressBar processProgress={processProgress} />
              </div>
            </div>
          ) : (
            <div className="outer-restults-container">
              {/* RESULT CARD */}
              {complete && !isRating ? (
                <div className="improve-container">
                  {/* TITLE */}
                  <div className="title-container">
                    <div className="vulavula-card-heading">
                      {improveCardInfo.title}
                    </div>
                    <img
                      className="smile-icon"
                      src={SmileIcon}
                      alt="smile icon"
                    />
                  </div>

                  <div className="button-container">
                    {/* TRY AGAIN BUTTON */}
                    <div
                      className="result-button"
                      style={{
                        background: "#CC4A2B",
                        border: "1px solid #CC4A2B",
                      }}
                      onClick={this.handleClearStates}
                    >
                      <div
                        className="result-button-text"
                        style={{ color: "white" }}
                      >
                        {improveCardInfo.tryAgainButtonText}
                      </div>
                      <img src={RepeatIcon} alt="repeat icon" />
                    </div>

                    {/* RATE US BUTTON */}
                    <div
                      className="result-button"
                      style={{
                        color: "#3A2730",
                        border: "1px solid #3a2730",
                      }}
                      onClick={this.setIsRating}
                    >
                      <div
                        className="result-button-text"
                        style={{
                          color: "#3A2730",
                        }}
                      >
                        {improveCardInfo.rateUsButtonText}
                      </div>
                      <img src={FullStarIcon} alt="full star icon" />
                    </div>
                  </div>
                </div>
              ) : (
                <ResultCard
                  handleClearStates={this.handleClearStates}
                  handleRecordingState={this.handleRecordingState}
                  transcriptionResults={transcriptionResults}
                  languageId={languageId}
                  dispatch={this.props.dispatch}
                />
              )}
            </div>
          )}

          {/* RATE US MODAL */}
          {isRating && (
            <RatingModal
              modalInfo={ratingModalTranscribeInfo}
              handleClearStates={this.handleClearStates}
              dispatch={this.props.dispatch}
              globalState={this.props.globalState}
            />
          )}
        </div>
      </div>
    );
  }
}

Transcribe.propTypes = {
  dispatch: PropTypes.func,
  globalState: PropTypes.object,
  navigate: PropTypes.func,
};

const TranscribeWithEffect = (props) => {
  const nav = props.navigate;
  useEffect(() => {
    // Check if the user has accepted terms for TRANSCRIBE
    const hasAcceptedTerms = getCookie("consent_transcribe");

    if (!hasAcceptedTerms) {
      nav(HOME);
    }
  }, [nav]);

  return <Transcribe {...props} />;
};

export default TranscribeWithEffect;
